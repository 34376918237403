// **************************************** =========== Start Home Page (1) Our Software Download Area  ===========  ****************************************//
.echo-software-download-area{
    padding-bottom: 90px;
    .echo-software-download-full-content-bg{
        background-color: #537FE7;
        padding: 80px 150px;
        overflow: hidden;
        position: relative;
        transition: all .4s;
        &:hover{
            .echo-software-download-img-content{
                transform: scale(110%);
            }
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #181823;
            opacity: 0;
        }
        .echo-software-download-img-content{
            position: absolute;
            top: -1px;
            right: 50px;
            transition: all 0.4s;
            transform: scale(100%);
            transition: all 0.4s;
        }
        .echo-software-download-full-content{
            display: flex;
            position: relative;
            z-index: 1;
            .echo-software-download-text-content{
                // flex-basis: 30%;
                .echo-software-download-title{
                h2{
                    font-size: 40px;
                    line-height: 50px;
                    color: #fff;
                }
        }
        .echo-software-download-sub-title{
            p{
                color: #fff;
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
            }
        }
        .echo-software-download-place{
            display: flex;
            gap: 25px;
            margin-top: 32px;
            a{
                border: 1px solid #fff;
                padding: 20px 55px;
                color: #fff;
                position: relative;
                font-family:'Bona Nova', serif;
                font-size: 16px;
                font-weight: 700;
                svg{
                    margin-right: 8px;
                }
            }
        }
            }
        }
    }
    &.home-three{
        padding-top: 290px;
        .echo-software-download-full-content-bg{
            background: #7817F2;
        }
    }
    &.inner{
        padding-bottom: 100px;
    }
    &.home-seven{
        .echo-software-download-full-content-bg{
            background: #9F68F5;
        }
    }
    &.home-eight{
        .echo-software-download-full-content-bg{
            background: #47164F;
            .echo-software-download-full-content .echo-software-download-text-content .echo-software-download-title h2{
                font-family: 'Inter', sans-serif;
            }
        }
    }
}


.echo-hero-section.home-three{
    background: url(../images/home-1/hero/banner-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 900px;
    padding: 250px 0 120px 0;
    .echo-hero-inner{
        .banner-content{
            .content-catagory-tag{
                background: #F05600;
                color: #ffffff;
                width: 120px;
                height: 35px;
                border-radius: 5px;
                display: block;
                font-weight: 400;
                text-align: center;
                pointer-events: none;
                font-size: 14px;
                line-height: 35px;
                text-transform: uppercase;
                font-family: 'Inter';
            }
            .contents{
                margin-top: 30px;
                .content-title1{
                    font-family: 'Bona Nova';
                    font-weight: 700;
                    font-size: 60px;
                    line-height: 70px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                    margin: 0;
                    a{
                        background-size: 200% 2px;
                        background-position: 100% 100%;
                        background-repeat: no-repeat;
                        background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), color-stop(50%, transparent));
                        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                        -webkit-transition: all 500ms;
                        transition: all 500ms;
                        &:hover{
                            background-position: 0% 100%;
                            color: #ffffff;
                        }
                    }
                }
                .desc{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #ffffff;
                    margin-top: 30px;
                    margin-bottom: 10px;
                    padding-bottom: 20px;
                    opacity: .8;
                    border-bottom: .5px solid rgba(255, 255, 255, 0.4);
                }
                .content-bottom-info{
                    ul{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        li{
                            list-style: none;
                            margin-right: 20px;
                            color: #ffffff;
                            opacity: .8;
                            i{
                                margin-right: 7px;
                            }
                            &.author{
                                align-items: center;
                                display: flex;
                                img{
                                    margin-right: 7px;
                                }
                            }
                        }
                    }
                    
                }
                .echo-button-wrapper{
                    display: flex;
                    align-items: center;
                    @media #{$large-mobile} {
                        align-items: flex-start;
                        flex-direction: column;
                    }
                    .rts-btn{
                        background: var(--color-primary-m);
                        color: #ffffff;
                        width: 170px;
                        height: 54px;
                        line-height: 54px;
                        text-align: center;
                        border-radius: 10px;
                        margin-right: 30px;
                        transition: all .4s;
                        &:hover{
                            background: #ffffff;
                            color: #000;
                            i{
                                transform: rotate(0deg);
                            }
                        }
                        i{
                            margin-left: 10px;
                            transform: rotate(-45deg);
                            transition: all .2s;
                        }
                    }
                    .vedio-icone {
                        position: relative;
                    }
                    
                    .video-play-button {
                        box-sizing: content-box;
                        display: block;
                        width: 32px;
                        height: 44px;
                        border-radius: 50%;
                        padding: 18px 20px 18px 28px;
                        display: flex;
                    
                    
                        span {
                            &.outer-text {
                                border: none;
                                font-family: 'Bona Nova';
                                font-size: 20px;
                                min-width: max-content;
                                margin-left: 75px;
                                position: relative;
                                margin-top: -12px;
                                color: #FFFFFF;
                                font-weight: 500;
                            }
                        }
                    
                        &::after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            display: block;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            transition: all 200ms;
                            background: rgba(255, 255, 255, 0.05);
                            border: .5px solid #ffffff;
                        }
                    }
                    
                    .video-overlay {
                        position: fixed;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: rgba(0, 0, 0, 0.8);
                        opacity: 0;
                        transition: all ease 500ms;
                        display: none;
                    }
                    
                    .video-overlay-close {
                        position: absolute;
                        z-index: 1000;
                        top: 15px;
                        right: 20px;
                        width: 25px;
                        font-size: 36px;
                        line-height: 1;
                        font-weight: 400;
                        color: #fff;
                        text-decoration: none;
                        cursor: pointer;
                        transition: all 200ms;
                    }
                    
                    .video-overlay.open {
                        position: fixed;
                        z-index: 1000;
                        opacity: 1;
                        display: block;
                    }
                    
                    .video-play-button span {
                        display: block;
                        position: relative;
                        z-index: 3;
                        width: 0;
                        height: 0;
                        border-left: 14px solid #ffffff;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 47%;
                    }
                    
                    .video-overlay {
                        iframe {
                            width: 70%;
                            height: 70%;
                            margin: auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 50%;
                            top: 50%;
                            position: relative;
                            transform: translateY(-50%);
                        }
                    }
                    
                }
                
            }
        }
        .echo-hero-right-side{
            .right-side-inner{
                background: rgba(24, 24, 35, 0.9);
                padding: 50px 30px;
                .sub-title{
                    font-family: 'Bona Nova';
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 30px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    padding-bottom: 25px;
                    margin-bottom: 30px;
                    border-bottom: 2px solid #FFFFFF;
                }
                .content-box{
                    ul{
                        padding: 0;
                        margin: 0;
                        .wrapper{
                            list-style: none;
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            margin-bottom: 30px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            .image-area{
                                flex-basis: 38%;
                                img{
                                    width: 100%;
                                }
                            }
                            .content{
                                flex-basis: 62%;
                                .content-catagory-tag{
                                    background: #F05600;
                                    border-radius: 4px;
                                    padding: 5px 10px;
                                    color: #ffffff;
                                    font-family: 'Inter';
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 22px;
                                    text-align: center;
                                    text-transform: uppercase;
                                    color: #FFFFFF;
                                    &.tech{
                                        background: #00D971;
                                    }
                                }
                                .content-title1{
                                    font-family: 'Bona Nova';
                                    font-weight: 700;
                                    font-size: 25px;
                                    line-height: 35px;
                                    text-transform: capitalize;
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                    color: #FFFFFF;
                                    a{
                                        background-size: 200% 1px;
                                        background-position: 100% 100%;
                                        background-repeat: no-repeat;
                                        background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), color-stop(50%, transparent));
                                        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                                        -webkit-transition: all 500ms;
                                        transition: all 500ms;
                                        &:hover{
                                            background-position: 0% 100%;
                                            color: #ffffff;
                                        }
                                    }
                                }
                                .content-bottom-info{
                                    ul{
                                        padding: 0;
                                        margin: 0;
                                        li{
                                            list-style: none;
                                            font-family: 'Inter';
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 22px;
                                            text-transform: lowercase;
                                            color: #FFFFFF;
                                            margin-top: 5px;
                                            margin-bottom: 0;
                                            i{
                                                margin-right: 8px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.echo-hero-section.home-four{
    background: url(../images/home-1/hero/banner-bg4.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 65px 0;
    .echo-full-hero-content{
        .echo-latest-news-main-content{
            margin-left: 8px;
            margin-right: 8px;
            background: #FFFFFF;
            border-radius: 25px;
            .echo-latest-news-img{
                overflow: visible;
                position: relative;
                a{
                    overflow: hidden;
                    border-radius: 15px 15px 0 0;
                }
                .content-catagory-tag{
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: #FE4358;
                    color: #ffffff;
                    width: 120px;
                    height: 35px;
                    border-radius: 5px;
                    display: block;
                    font-weight: 700;
                    text-align: center;
                    pointer-events: none;
                    font-size: 14px;
                    line-height: 35px;
                    text-transform: uppercase;
                    font-family: 'Inter';
                    &.tech{
                        background: #00D971;
                    }
                }
            }
            .content{
                padding: 15px 30px 30px 30px;
            }
            .echo-latest-news-single-title{
                margin-top: 35px;
                h5{
                    font-family: 'Bona Nova';
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 55px;
                    text-align: center;
                    text-transform: capitalize;
                    color: #181823;
                    margin-bottom: 30px;
                    a{
                        background-size: 200% 2px;
                    }
                }
            }
            .echo-latest-news-time-views{
                justify-content: center;
                text-align: center;
                color: #5E5E5E;
                a{
                    padding: 0 10px;
                    margin: 0;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 10px;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #454545bd;
                    }
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                    
                    i{
                        margin-right: 8px;
                    }
                }
            }
        }
        .echo-banner-right-area-content{
            background: linear-gradient(164.54deg, #1F1C2A -0.33%, rgba(31, 28, 42, 0.68) 99.62%);
            border-radius: 20px;
            padding: 25px 30px 35px 30px;
            ul{
                padding: 0;
                margin: 0;
                li{
                    list-style: none;
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                    gap: 15px;
                    margin-bottom: 10px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #FFFFFF4f;
                    &:last-child{
                        border: none;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                    .content{
                        flex-basis: 65%;
                        h5{
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 32px;
                            margin-top: 10px;
                            margin-bottom: 20px;
                            a{
                                color: #FFFFFF;
                                &:hover{
                                    background-position: 0% 100%;
                                    background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                                }
                            }
                        }
                        .desc{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            color: #FFFFFF;
                            opacity: .8;
                            i{
                                margin-right: 10px;
                            }
                        }
                    }
                    .echo-latest-news-img{
                        flex-basis: 35%;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.echo-home-1-hero-area-top-story{
    .echo-top-story{
        &.first{
            display: block;
            .echo-story-picture{
                width: 100%;
                margin-bottom: 15px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .echo-story-text{
                h4{
                    font-size: 23px;
                    line-height: 33px;
                    font-weight: 700;
                    @media (min-width: 768px) and (max-width: 991px){
                        text-align: left;
                        margin-bottom: 4px;
                    }
                }
                @media (min-width: 768px) and (max-width: 991px){
                    .echo-trending-post-bottom-icons{
                        text-align: left;
                    }
                }
            }
        }
    }
    &.story-review{
        .echo-top-story{
            display: block !important;
            margin: 20px 0;
            .echo-story-picture{
                position: relative;
                z-index: 1;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #181823;
                    opacity: .4;
                    z-index: 1;
                }
                &::after{
                    content: '7.5';
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    color: #FFFFFF;
                    z-index: 1;
                    width: 50px;
                    height: 50px;
                    line-height: 43px;
                    text-align: center;
                    border-radius: 50%;
                    border-color: #FFFFFF;
                    border-width: 5px 5px 5px 5px;
                    border-style: solid;
                }
            }
            .echo-story-text{
                display: flex;
                align-items: center;
                margin-top: 15px;
                .number{
                    flex-basis: 25%;
                    font-weight: 400;
                    font-size: 60px;
                    line-height: 70px;
                    text-transform: capitalize;
                    color: #F16228;
                }
                .content{
                    flex-basis: 75%;
                }
            }
        }
    }
}

// **************************************** =========== End Home Page (1) Our Software Download Area  ===========  ****************************************//

// **************************************** =========== Start Home Page (2) Our Banner Area  ===========  ****************************************//
.echo-banner{
    padding-top: 40px;
    padding-bottom: 50px;
    .echo-banner-slider-button{
        position: absolute;
        top: 22px;
        right: 70px;
        .swiper-button-next{
            background-color:#53B7BD;
            left: 40px;
            height: 30px;
            width: 30px;
            &::after{
                font-size: 15px;
                color: #ffffff;
                content: '\f105';
                font-family: "Font Awesome 6 Pro" !important;
            }
            &:hover{
                background-color:#53B7BD;
                &::after{
                    color: #fff;
                }
            }
        }
        .swiper-button-prev{
            background-color: #E6E6E6;
            width: 30px;
            height: 30px;
            &::after{
                font-size: 15px;
                color: #5E5E5E;
                content: '\f104';
                font-family: "Font Awesome 6 Pro" !important;
            }
            &:hover{
                background-color: #53B7BD;
                &::after{
                    color: #fff;
                }
            }
        }
    }
    .echo-banner-mani-content{
        position: relative;
        .echo-banner-text{
            max-width: 690px;
            height: 295;
            background-color: #f9f9f9;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 30px;
            .echo-banner-heading{
                margin-top: 20px;
                h1{
                    font-size: 60px;
                    line-height: 70px;
                    margin: 0;
                }
            }
            .echo-3-align-icons{
                margin-top: 12px;
                display: flex;
                gap: 25px;
                a{
                    font-size: 14px;
                    font-weight: 500;
                    i{
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .echo-banner-right-itme{
        position: relative;
        .echo-banar-right-img{
            position: relative;
            .echo-banner-shep{
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .echo-banner-right-heading{
            margin-top: 25px;
            h5{
                margin: 0;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }
    .echo-banner-right-itme:last-child{
        margin-top: 20px;
    }
}


// **************************************** =========== End Home Page (2) Our Banner Area  =========== ****************************************//

.echo-latest-news-area{
    background: #f9f9f9;
    @media (min-width: 992px) and (max-width: 1200px) {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    @media (max-width: 767px) {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    @media (max-width: 500px) {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    &.home-four{
        background: #ffffff;
        padding-bottom: 90px;
        .echo-latest-news-content{
            .title-area{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0;
                .sub-title{
                    width: 85%;
                    position: relative;
                    margin-bottom: 0;
                    &::after{
                        content: "";
                        position: absolute;
                        width: 72%;
                        right: 30px;
                        height: 1px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #5E5E5E;
                        opacity: 0.5;
                    }
                }
                .see-all-btn{
                    border: 1px solid #5E5E5E6b;
                    padding: 10px 22px;
                    border-radius: 10px;
                    transition: all .4s;
                    font-family: 'Bona Nova';
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 30px;
                    color: #5E5E5E;
                    background: none;
                    &:hover{
                        background: #FF4962;
                        border: 1px solid #FF4962;
                        color: #FFFFFF;
                    }
                    i{
                        margin-left: 5px;
                    }
                }
            }
            .echo-latest-news-full-content{
                text-align: center;
                .echo-latest-news-main-content{
                    margin-left: 8px;
                    margin-right: 8px;
                }
                .echo-latest-news-img{
                    overflow: visible;
                    position: relative;
                    &:hover{
                        &::after{
                            opacity: .4;
                        }
                        .vedio-icone{
                            .video-play-button{
                                transform: translate(-50%, -50%) scale(1);
                            }
                        }
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: #181823;
                        opacity: 0;
                        border-radius: 15px;
                        z-index: 0;
                        transition: all .4s;
                    }
                    a{
                        overflow: hidden;
                        border-radius: 15px;
                    }
                    .content-catagory-tag{
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #FE4358;
                        color: #ffffff;
                        width: 150px;
                        height: 35px;
                        border-radius: 5px;
                        display: block;
                        font-weight: 700;
                        text-align: center;
                        pointer-events: none;
                        font-size: 14px;
                        line-height: 35px;
                        text-transform: uppercase;
                        font-family: 'Inter';
                        z-index: 1;
                    }
                    
                    .video-play-button {
                        box-sizing: content-box;
                        display: block;
                        width: 32px;
                        height: 44px;
                        border-radius: 50%;
                        padding: 18px 20px 18px 28px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0);
                        z-index: 1;
                        transition: all .4s;
                    
                        span {
                            &.outer-text {
                                border: none;
                                font-family: 'Bona Nova';
                                font-size: 20px;
                                min-width: max-content;
                                margin-left: 75px;
                                position: relative;
                                margin-top: -12px;
                                color: #FFFFFF;
                                font-weight: 500;
                            }
                        }
                    
                        &::after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            display: block;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            transition: all 200ms;
                            background: #FFFFFF;
                            border: .5px solid #ffffff;
                            animation-name: videobuttonpopup;
                            animation-duration: 1.5s;
                            animation-iteration-count: infinite;
                        }
                    }
                    
                    .video-overlay {
                        position: fixed;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: rgba(0, 0, 0, 0.8);
                        opacity: 0;
                        transition: all ease 500ms;
                        display: none;
                    }
                    
                    .video-overlay-close {
                        position: absolute;
                        z-index: 1000;
                        top: 15px;
                        right: 20px;
                        width: 25px;
                        font-size: 36px;
                        line-height: 1;
                        font-weight: 400;
                        color: #fff;
                        text-decoration: none;
                        cursor: pointer;
                        transition: all 200ms;
                    }
                    
                    .video-overlay.open {
                        position: fixed;
                        z-index: 1000;
                        opacity: 1;
                        display: block;
                    }
                    
                    .video-play-button span {
                        display: block;
                        position: relative;
                        z-index: 3;
                        width: 0;
                        height: 0;
                        border-left: 14px solid #FE4358;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 47%;
                    }
                    
                    .video-overlay {
                        iframe {
                            width: 70%;
                            height: 70%;
                            margin: auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 50%;
                            top: 50%;
                            position: relative;
                            transform: translateY(-50%);
                        }
                    }
                }
                .echo-latest-news-single-title{
                    margin-top: 35px;
                    h5{
                        font-family: 'Bona Nova';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 25px;
                        line-height: 35px;
                        text-align: center;
                        text-transform: capitalize;
                        color: #181823;
                    }
                }
                .echo-latest-news-time-views{
                    justify-content: center;
                    position: relative;
                    padding-top: 20px;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 50%;
                        height: 1px;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #171717;
                        opacity: .4;
                    }
                    a{
                        margin: 0;
                        padding: 0 10px;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 10px;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background: #454545bd;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }

                        i{
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }
    &.home-seven{
        background: #9F68F5;
        @media(max-width:500px){
            padding: 30px 0;
        }
        .hm5-feature-title{
            .hm-5-main-title{
                h2{
                    color: #FFFFFF;
                    margin-bottom: 45px;
                    font-size: 40px;
                    line-height: 50px;
                    font-weight: 500;
                    font-family: 'Jost', sans-serif;
                }
            }
        }
        .echo-hero-baner.small{
            text-align: left;
           display: flex; 
           align-items: center;
           gap: 20px;
           margin-bottom: 33px;
           background: #FFFFFF;
           border: 1px solid #FFF;
           .echo-hero-banner-main-img{
                flex-basis: 30%;
                @media(max-width:1200px){
                    flex-basis: auto;
                }
            }
           .content{
                margin-top: 0;
                flex-basis: 70%;
                @media(max-width:1200px){
                    flex-basis: auto;
                }
                .author{
                    text-align: left;
                    font-weight: 500;
                }
                .echo-hero-title{
                    text-align: left;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 500;
                    a{
                        color: var(--color-heading-1);
                        background-image: linear-gradient(to right, #000 50%, transparent 50%);
                    }
                }
            }
        }
    }
}


.echo-feature-area{
    &.home-four{
        background: #181823;
        .title-area{
            margin-bottom: 20px;
            &.right{
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .sub-title{
                position: relative;
                margin-bottom: 0;
                color: #FFFFFF;
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
                img{
                    margin-right: 5px;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 72%;
                    right: 10px;
                    height: 1px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #FFFFFF;
                    opacity: 0.5;
                }
            }  
            .sect-title{
                position: relative;
                color: #FFFFFF;
                margin-bottom: 0;
                text-align: center;
                &::after,
                &::before{
                    content: '';
                    position: absolute;
                    width: 30%;
                    height: 1px;
                    top: 50%;
                    background: #FFFFFF;
                    transform: translateY(-50%);
                    opacity: .5;
                }
                &::after{
                    right: 0;
                }
                &::before{
                    left: 0;
                }
            } 
        }
        .echo-feature-area-full-content{
            margin-top: 40px;
            .echo-banner-right-area-content{
                ul{
                    padding: 0;
                    margin: 0;
                    li{
                        list-style: none;
                        display: flex;
                        align-items: center;
                        margin-top: 0;
                        gap: 15px;
                        margin-bottom: 10px;
                        padding-bottom: 25px;
                        border-bottom: 1px solid #FFFFFF33;
                        &:last-child{
                            border: none;
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                        .content{
                            flex-basis: 65%;
                            h5{
                                font-weight: 700;
                                font-size: 20px;
                                line-height: 30px;
                                margin-top: 10px;
                                margin-bottom: 15px;
                                a{
                                    color: #FFFFFF;
                                    &:hover{
                                        background-position: 0% 100%;
                                        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                                    }
                                }
                            }
                            .desc{
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 26px;
                                color: #FFFFFF;
                                opacity: .8;
                                i{
                                    margin-right: 10px;
                                }
                            }
                        }
                        .echo-latest-news-img{
                            flex-basis: 35%;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .echo-latest-news-main-content{
                text-align: center;
                margin-left: 8px;
                margin-right: 8px;
                .echo-latest-news-img{
                    overflow: visible;
                    position: relative;
                    z-index: 1;
                    &:hover{
                        .vedio-icone{
                            display: block;
                        }
                    }
                    a{
                        overflow: hidden;
                        border-radius: 15px;
                    }
                    .content-catagory-tag{
                        position: absolute;
                        bottom: -20px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #FE4358;
                        color: #ffffff;
                        width: 150px;
                        height: 40px;
                        border-radius: 5px;
                        display: block;
                        font-weight: 700;
                        text-align: center;
                        pointer-events: none;
                        font-size: 14px;
                        line-height: 40px;
                        text-transform: uppercase;
                        font-family: 'Inter';
                        z-index: 1;
                    }
                }
                .echo-latest-news-single-title{
                    margin-top: 45px;
                    h5{
                        font-family: 'Bona Nova';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 40px;
                        text-align: center;
                        text-transform: capitalize;
                        color: #ffffff;
                        margin-bottom: 22px;
                        a{
                            &:hover{
                                color: #FFFFFF;
                                background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                            }
                        }
                    }
                }
                .echo-latest-news-time-views{
                    justify-content: center;
                    position: relative;
                    padding-top: 22px;
                    color: #FFFFFF;
                    opacity: .8;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 50%;
                        height: 1px;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #FFFFFF;
                        opacity: .2;
                    }
                    a{
                        margin: 0;
                        padding: 0 10px;
                        position: relative;
                        &::after{
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 10px;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background: #454545bd;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }

                        i{
                            margin-right: 8px;
                        }
                    }
                }
            }
            .right-side-inner{
                .echo-feature-area-right-site-follower{
                    background: none;
                    margin-top: 0;
                    padding: 0 0 30px 0;
                    ul{
                        margin-top: 0;
                        margin-bottom: 0;
                        li{
                            border: 1px solid #FFFFFF8f;
                            padding: 15px 15px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            border-radius: 5px;
                            margin-bottom: 15px;
                            width: 47%;
                            transition: all .4s;
                            &:hover{
                                a{
                                    color: #FF4962;
                                }
                            }
                            a{
                                color: #FFFFFF;
                                margin-bottom: 0;
                                transition: all .4s;
                            }
                        }
                        li:nth-child(1),
                        li:nth-child(3),
                        li:nth-child(5){
                            margin-right: 20px;
                        }
                        li:nth-child(5),
                        li:nth-child(6){
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    &.area-2{
        background: #FFFFFF;
        padding-bottom: 100px;
        .echo-feature-area-content{
            .title-area{
                margin-bottom: 20px;
                .sub-title{
                    position: relative;
                    margin-bottom: 0;
                    color: #181823;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    img{
                        margin-right: 5px;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        width: 60%;
                        right: 10px;
                        height: 1px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #171717;
                        opacity: 0.5;
                    }
                }  
                .right-side-title{
                    padding-top: 5px;
                    .sect-title{
                        position: relative;
                        color: #181823;
                        margin-bottom: 0;
                        text-align: center;
                        font-size: 30px;
                        line-height: 40px;
                        text-align: center;
                        &::after,
                        &::before{
                            content: '';
                            position: absolute;
                            width: 25%;
                            height: 1px;
                            top: 50%;
                            background: #171717;
                            transform: translateY(-50%);
                            opacity: .5;
                        }
                        &::after{
                            right: 0;
                        }
                        &::before{
                            left: 0;
                        }
                    }

                } 
            }
            .echo-feature-full-content{
                padding-bottom: 20px;
                border-bottom: 1px solid #5E5E5E42;
                .echo-feature-area-site-title{
                    .echo-feature-area-last-news {
                        margin-top: 0;
                        .echo-feature-area-last-content{
                            .echo-feature-area-last-content-img{
                                border-radius: 30px;
                                flex-basis: 35%;
                            }
                            .echo-feature-area-last-content-text{
                                flex-basis: 65%;
                                .content-catagory-tag{
                                    background: #FE4358;
                                    color: #ffffff;
                                    width: 150px;
                                    height: 40px;
                                    border-radius: 5px;
                                    display: block;
                                    font-weight: 700;
                                    text-align: center;
                                    pointer-events: none;
                                    font-size: 14px;
                                    line-height: 40px;
                                    text-transform: uppercase;
                                    font-family: 'Inter';
                                    z-index: 1;
                                }
                                h3{
                                    margin-top: 20px;
                                    font-weight: 700;
                                    font-size: 40px;
                                    line-height: 50px;
                                }
                                .echo-latest-news-time-views{
                                    justify-content: center;
                                    position: relative;
                                    padding-top: 0;
                                    color: #454545;
                                    opacity: .8;
                                    a{
                                        margin: 0;
                                        padding: 0 10px;
                                        position: relative;
                                        &::after{
                                            content: '';
                                            position: absolute;
                                            width: 1px;
                                            height: 10px;
                                            right: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            background: #454545bd;
                                        }
                                        &:last-child{
                                            &::after{
                                                display: none;
                                            }
                                        }
                
                                        i{
                                            margin-right: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .echo-feature-right-area{
                    .echo-feature-area-last-content{
                       .echo-feature-area-last-content-text{
                            background: url(../images/home-1/feature-left/item-11.png);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            padding: 30px;
                            border-radius: 20px;
                            position: relative;
                            .content-catagory-tag{
                                background: #FE4358;
                                color: #ffffff;
                                width: 120px;
                                height: 35px;
                                border-radius: 5px;
                                display: block;
                                font-weight: 700;
                                text-align: center;
                                pointer-events: none;
                                font-size: 14px;
                                line-height: 35px;
                                text-transform: uppercase;
                                font-family: 'Inter';
                                z-index: 1;
                            }
                            h3{
                                font-weight: 700;
                                font-size: 40px;
                                line-height: 50px;
                                text-transform: capitalize;
                                color: #FFFFFF;
                                margin-top: 50px;
                                a{
                                    color: #FFFFFF;
                                    background-image: linear-gradient(to right, #FFFFFF 50%, transparent 50%);
                                    &:hover{
                                        color: #FFFFFF;
                                        background-image: linear-gradient(to right, #FFFFFF 50%, transparent 50%);
                                    }
                                }
                            }
                            .desc{
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 26px;
                                color: #FFFFFF;
                            }

                        } 
                    }
                    .echo-right-side-small-content{
                        padding: 0;
                        .content-inner{
                            list-style: none;
                            display: flex;
                            align-items: center;
                            background: #FFFFFF;
                            box-shadow: 20px 20px 60px rgba(0, 0, 1, 0.05);
                            border-radius: 20px;
                            padding: 25px 20px;
                            .number{
                                flex-basis: 25%;
                                h3{
                                    font-weight: 400;
                                    font-size: 60px;
                                    line-height: 70px;
                                    text-transform: capitalize;
                                    color: #5E5E5E;
                                    opacity: 0.5;
                                }
                            }
                            .content{
                                flex-basis: 75%;
                                h3{
                                    font-weight: 700;
                                    font-size: 18px;
                                    line-height: 28px;
                                    text-transform: capitalize;
                                    color: #181823;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .button-area{
                text-align: center;
                margin-top: 50px;
                .rts-btn{
                    padding: 20px;
                    border-radius: 10px;
                    background: none;
                    border: 1px solid #5E5E5E9e;
                    font-family: 'Bona Nova';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 30px;
                    text-transform: capitalize;
                    color: #5E5E5E;
                    i{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
.echo-popular-news-area{
    &.home-four{
        .echo-popular-news-area-content{
            .title-area{
                margin-bottom: 20px;
                .sub-title{
                    position: relative;
                    margin-bottom: 0;
                    color: #181823;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    img{
                        margin-right: 5px;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        width: 60%;
                        right: 10px;
                        height: 1px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #171717;
                        opacity: 0.5;
                    }
                }  
                .sect-title{
                    position: relative;
                    color: #181823;
                    margin-bottom: 0;
                    text-align: center;
                    font-size: 30px;
                    line-height: 40px;
                    &::after,
                    &::before{
                        content: '';
                        position: absolute;
                        width: 20%;
                        height: 1px;
                        top: 50%;
                        background: #171717;
                        transform: translateY(-50%);
                        opacity: .5;
                    }
                    &::after{
                        right: 0;
                    }
                    &::before{
                        left: 0;
                    }
                } 
            }
           .echo-popular-news-area-full-content{
            .left-side-content{
                .echo-latest-news-main-content{
                    text-align: center;
                    margin: 0 8px 30px 8px;
                    .echo-latest-news-img{
                        overflow: visible;
                        position: relative;
                        z-index: 1;
                        &:hover{
                            &::after{
                                opacity: .4;
                            }
                            .vedio-icone{
                                display: block;
                            }
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: #181823;
                            opacity: 0;
                            border-radius: 20px;
                            z-index: 0;
                            transition: all .4s;
                        }
                        a{
                            overflow: hidden;
                            border-radius: 20px;
                        }
                        .content-catagory-tag{
                            position: absolute;
                            bottom: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            background: #FE4358;
                            color: #ffffff;
                            width: 150px;
                            height: 40px;
                            border-radius: 5px;
                            display: block;
                            font-weight: 700;
                            text-align: center;
                            pointer-events: none;
                            font-size: 14px;
                            line-height: 40px;
                            text-transform: uppercase;
                            font-family: 'Inter';
                            z-index: 1;
                        }
                        .vedio-icone {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 1;
                            display: none;
                            transition: all .4s;
                        }
                        
                        .video-play-button {
                            box-sizing: content-box;
                            display: block;
                            width: 32px;
                            height: 44px;
                            border-radius: 50%;
                            padding: 18px 20px 18px 28px;
                            display: flex;
                        
                            span {
                                &.outer-text {
                                    border: none;
                                    font-family: 'Bona Nova';
                                    font-size: 20px;
                                    min-width: max-content;
                                    margin-left: 75px;
                                    position: relative;
                                    margin-top: -12px;
                                    color: #FFFFFF;
                                    font-weight: 500;
                                }
                            }
                        
                            &::after {
                                content: "";
                                position: absolute;
                                z-index: 1;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                display: block;
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                transition: all 200ms;
                                background: #FFFFFF;
                                border: .5px solid #ffffff;
                                animation-name: videobuttonpopup;
                                animation-duration: 1.5s;
                                animation-iteration-count: infinite;
                            }
                        }
                        
                        .video-overlay {
                            position: fixed;
                            z-index: -1;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background: rgba(0, 0, 0, 0.8);
                            opacity: 0;
                            transition: all ease 500ms;
                            display: none;
                        }
                        
                        .video-overlay-close {
                            position: absolute;
                            z-index: 1000;
                            top: 15px;
                            right: 20px;
                            width: 25px;
                            font-size: 36px;
                            line-height: 1;
                            font-weight: 400;
                            color: #fff;
                            text-decoration: none;
                            cursor: pointer;
                            transition: all 200ms;
                        }
                        
                        .video-overlay.open {
                            position: fixed;
                            z-index: 1000;
                            opacity: 1;
                            display: block;
                        }
                        
                        .video-play-button span {
                            display: block;
                            position: relative;
                            z-index: 3;
                            width: 0;
                            height: 0;
                            border-left: 14px solid #FE4358;
                            border-top: 8px solid transparent;
                            border-bottom: 8px solid transparent;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            left: 47%;
                        }
                        
                        .video-overlay {
                            iframe {
                                width: 70%;
                                height: 70%;
                                margin: auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 50%;
                                top: 50%;
                                position: relative;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    .echo-latest-news-single-title{
                        margin-top: 45px;
                        h5{
                            font-family: 'Bona Nova';
                            text-align: center;
                            font-weight: 700;
                            font-size: 25px;
                            line-height: 35px;
                            text-align: center;
                            text-transform: capitalize;
                            color: #181823;
                            margin-bottom: 15px;
                            a{
                                &:hover{
                                    color: #181823;
                                    background-image: linear-gradient(to right, #181823 50%, transparent 50%);
                                }
                            }
                        }
                    }
                    .echo-latest-news-time-views{
                        justify-content: center;
                        position: relative;
                        padding-top: 15px;
                        color: #5E5E5E;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 50%;
                            height: 1px;
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            background: #181823;
                            opacity: .2;
                        }
                        a{
                            margin: 0;
                            padding: 0 10px;
                            position: relative;
                            &::after{
                                content: '';
                                position: absolute;
                                width: 1px;
                                height: 10px;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                background: #454545bd;
                            }
                            &:last-child{
                                &::after{
                                    display: none;
                                }
                            }
    
                            i{
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            .title-area.right{
                margin-top: 5px;
            }
            .echo-banner-right-area-content{
                .right-side-news{

                    ul{
                        padding: 0;
                        margin: 0;
                        li{
                            list-style: none;
                            display: flex;
                            align-items: center;
                            margin-top: 0;
                            gap: 15px;
                            margin-bottom: 0;
                            padding-bottom: 25px;
                            border-bottom: 1px solid #FFFFFF33;
                            &:last-child{
                                border: none;
                                padding-bottom: 0;
                                margin-bottom: 0;
                            }
                            .content{
                                flex-basis: 65%;
                                .catagory-tag{
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 22px;
                                    text-transform: uppercase;
                                    color: #FF7474;
                                }
                                h5{
                                    font-weight: 700;
                                    font-size: 25px;
                                    line-height: 35px;
                                    margin-top: 5px;
                                    margin-bottom: 15px;
                                    color: #181823;
                                    a{
                                        color: #181823;
                                        &:hover{
                                            background-position: 0% 100%;
                                            background-image: linear-gradient(to right, #181823 50%, transparent 50%);
                                        }
                                    }
                                }
                                .desc{
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 26px;
                                    color: #5E5E5E;
                                    i{
                                        margin-right: 10px;
                                    }
                                }
                            }
                            .echo-latest-news-img{
                                flex-basis: 35%;
                                border-radius: 50%;
                            }
                        }
                    }
                }
                .right-side-gallery{
                    .title{
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 40px;
                        color: #181823;
                        position: relative;
                        text-align: center;
                        margin-top: 40px;
                        &::before,
                        &::after{
                            content: '';
                            position: absolute;
                            width: 25%;
                            height: 1px;
                            top: 50%;
                            transform: translateY(-50%);
                            background: #171717;
                            opacity: .5;
                        }
                        &::before{
                            left: 0;
                        }
                        &::after{
                            right: 0;
                        }
                    }
                    .gallery-inner{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .gallery-item{
                            list-style: none;
                            width: 46%;
                            cursor: pointer;
                            transition: all .4s;
                            overflow: hidden;
                            border-radius: 10px;
                            &:hover{
                                img{
                                    transform: scale(110%);
                                }
                            }
                            img{
                                width: 100%;
                                transform: scale(100%);
                                transition: all .4s;
                            }
                        }
                    }
                }
            }
           } 
        }
    }
}
.echo-video-area{
    &.home-four{
        background: none;
        padding: 0;
        position: relative;
        .echo-video-area-inner{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 150px;
            z-index: 2;

            .vedio-icone {
                position: relative;
            }
            
            .video-play-button {
                box-sizing: content-box;
                display: block;
                position: relative;
                margin: 0 auto;
                width: 32px;
                height: 44px;
                border-radius: 50%;
                padding: 18px 20px 18px 28px;
                display: flex;
            
                span {
                    &.outer-text {
                        border: none;
                        font-family: 'Bona Nova';
                        font-size: 20px;
                        min-width: max-content;
                        margin-left: 75px;
                        position: relative;
                        margin-top: -12px;
                        color: #FFFFFF;
                        font-weight: 500;
                    }
                }
            
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                    transition: all 200ms;
                    background: #FFFFFF;
                    border: .5px solid #ffffff;
                    z-index: 2;
                }
                &::before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    transition: all 200ms;
                    background: rgba(255, 255, 255, 0.15);
                    backdrop-filter: blur(40px);
                    z-index: 2;
                }
            }
            
            .video-overlay {
                position: fixed;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.8);
                opacity: 0;
                transition: all ease 500ms;
                display: none;
            }
            
            .video-overlay-close {
                position: absolute;
                z-index: 1000;
                top: 15px;
                right: 20px;
                width: 25px;
                font-size: 36px;
                line-height: 1;
                font-weight: 400;
                color: #fff;
                text-decoration: none;
                cursor: pointer;
                transition: all 200ms;
            }
            
            .video-overlay.open {
                position: fixed;
                z-index: 1000;
                opacity: 1;
                display: block;
            }
            
            .video-play-button span {
                display: block;
                position: relative;
                z-index: 3;
                width: 0;
                height: 0;
                border-left: 25px solid #FE4358;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 47%;
            }
            
            .video-overlay {
                iframe {
                    width: 70%;
                    height: 70%;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 50%;
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                }
            }
        }
        .swiper-container{
            .slider-inner{
                background: url(../images/home-1/video-left/video-bg2.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 180px;
                padding-bottom: 0;
                .echo-video-bottom-area{
                    background: rgba(255, 255, 255, 0.05);
                    backdrop-filter: blur(40px);
                    border-radius: 30px 30px 0px 0px;
                    text-align: center;
                    margin-top: 200px;
                    .content{
                        padding: 40px 40px 100px 40px;
                        .content-catagory-tag{
                            background: #FE4358;
                            color: #ffffff;
                            width: 150px;
                            height: 35px;
                            border-radius: 5px;
                            display: block;
                            font-weight: 700;
                            text-align: center;
                            pointer-events: none;
                            font-size: 14px;
                            line-height: 35px;
                            text-transform: uppercase;
                            font-family: 'Inter';
                            z-index: 1;
                            margin: 0 auto;
                        }
                        h5{
                            font-weight: 700;
                            font-size: 50px;
                            line-height: 60px;
                            text-align: center;
                            text-transform: capitalize;
                            margin-top: 20px;
                            margin-bottom: 25px;
                            a{
                                color: #FFFFFF;
                                background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                                &:hover{
                                    background-position: 0% 100%;
                                    background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                                }
                            }
                        }
                        .echo-latest-news-time-views{
                            justify-content: center;
                            position: relative;
                            padding-top: 15px;
                            color: #FFFFFF;
                            padding-left: 15px;
                            a{
                                margin: 0;
                                padding: 0 10px;
                                position: relative;
                                &:first-child{
                                    padding-right: 15px;
                                }
                                &::after{
                                    content: '';
                                    position: absolute;
                                    width: 1px;
                                    height: 10px;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    background: #FFFFFF;
                                }
                                &:last-child{
                                    &::after{
                                        display: none;
                                    }
                                }
        
                                i{
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
            .swiper-btn{
                position: absolute;
                width: 70px;
                height: 50px;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                .swiper-button-next,
                .swiper-button-prev{
                    &::after{
                        display: none;
                    }
                    color: #FFFFFF;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    transition: all .4s;
                }
                .swiper-button-prev{
                    right: auto;
                    left: 0;
                    &:hover{
                        background-color: #FE4358;
                        border: 1px solid #FE4358;
                    }
                }
                .swiper-button-next{
                    right: 0;
                    left: auto;
                    background-color: #FE4358;
                    border: 1px solid #FE4358;
                    &:hover{
                        background: none;
                        border: 1px solid #FFFFFF;
                    }
                }
            }
        }
    }
    &.inner{
        background: url(../images/home-1/video-left/video-bg3.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 670px;
        position: relative;
        .echo-video-area-inner{
            padding: 200px 0;
            
            .video-play-button {
                box-sizing: content-box;
                display: block;
                position: absolute;
                margin: 0 auto;
                width: 32px;
                height: 44px;
                border-radius: 50%;
                padding: 18px 20px 18px 28px;
                display: flex;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            
            
                span {
                    &.outer-text {
                        border: none;
                        font-family: 'Bona Nova';
                        font-size: 20px;
                        min-width: max-content;
                        margin-left: 75px;
                        position: relative;
                        margin-top: -12px;
                        color: #FFFFFF;
                        font-weight: 500;
                    }
                }
            
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                    transition: all 200ms;
                    background: #FFFFFF;
                    border: .5px solid #ffffff;
                    z-index: 2;
                }
                &::before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    transition: all 200ms;
                    background: rgba(255, 255, 255, 0.15);
                    backdrop-filter: blur(40px);
                    z-index: 2;
                }
            }
            
            .video-overlay {
                position: fixed;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.8);
                opacity: 0;
                transition: all ease 500ms;
                display: none;
            }
            
            .video-overlay-close {
                position: absolute;
                z-index: 1000;
                top: 15px;
                right: 20px;
                width: 25px;
                font-size: 36px;
                line-height: 1;
                font-weight: 400;
                color: #fff;
                text-decoration: none;
                cursor: pointer;
                transition: all 200ms;
            }
            
            .video-overlay.open {
                position: fixed;
                z-index: 1000;
                opacity: 1;
                display: block;
            }
            
            .video-play-button span {
                display: block;
                position: relative;
                z-index: 3;
                width: 0;
                height: 0;
                border-left: 25px solid var(--color-primary);
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 47%;
            }
            
            .video-overlay {
                iframe {
                    width: 70%;
                    height: 70%;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 50%;
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                }
            }
        }
    }
    &.home-eight{
        background: none;
        padding: 0;
        position: relative;
        .echo-video-area-inner{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 150px;
            z-index: 2;

            .vedio-icone {
                position: relative;
            }
            
            .video-play-button {
                box-sizing: content-box;
                display: block;
                position: relative;
                margin: 0 auto;
                width: 32px;
                height: 44px;
                border-radius: 50%;
                padding: 18px 20px 18px 28px;
                display: flex;
            
                span {
                    &.outer-text {
                        border: none;
                        font-family: 'Bona Nova';
                        font-size: 20px;
                        min-width: max-content;
                        margin-left: 75px;
                        position: relative;
                        margin-top: -12px;
                        color: #FFFFFF;
                        font-weight: 500;
                    }
                }
            
                &::after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                    transition: all 200ms;
                    background: #FFFFFF;
                    border: .5px solid #ffffff;
                    z-index: 2;
                }
                &::before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: block;
                    width: 140px;
                    height: 140px;
                    border-radius: 50%;
                    transition: all 200ms;
                    background: rgba(255, 255, 255, 0.15);
                    backdrop-filter: blur(40px);
                    z-index: 2;
                }
            }
            
            .video-overlay {
                position: fixed;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.8);
                opacity: 0;
                transition: all ease 500ms;
                display: none;
            }
            
            .video-overlay-close {
                position: absolute;
                z-index: 1000;
                top: 15px;
                right: 20px;
                width: 25px;
                font-size: 36px;
                line-height: 1;
                font-weight: 400;
                color: #fff;
                text-decoration: none;
                cursor: pointer;
                transition: all 200ms;
            }
            
            .video-overlay.open {
                position: fixed;
                z-index: 1000;
                opacity: 1;
                display: block;
            }
            
            .video-play-button span {
                display: block;
                position: relative;
                z-index: 3;
                width: 0;
                height: 0;
                border-left: 25px solid #FE5301;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 47%;
            }
            
            .video-overlay {
                iframe {
                    width: 70%;
                    height: 70%;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 50%;
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                }
            }
        }
        .swiper-container-h8{
            .slider-inner{
                background: url(../images/home-1/video-left/video-bg4.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 180px;
                padding-bottom: 0;
                .echo-video-bottom-area{
                    background: #FFFFFF;
                    margin-top: 200px;
                    .content{
                        padding: 45px 40px 50px 40px;
                        @media(max-width:450px){
                            padding: 45px 20px 50px;
                        }
                        .content-catagory-tag{
                            background: #FE5301;
                            color: #ffffff;
                            width: 105px;
                            height: 40px;
                            display: block;
                            font-weight: 700;
                            text-align: center;
                            pointer-events: none;
                            font-size: 14px;
                            line-height: 40px;
                            text-transform: uppercase;
                            font-family: 'Inter';
                            z-index: 1;
                        }
                        h5{
                            font-weight: 500;
                            font-size: 50px;
                            line-height: 65px;
                            font-family: 'Inter', sans-serif;
                            text-align: start;
                            text-transform: capitalize;
                            margin-top: 20px;
                            margin-bottom: 25px;
                            @media(max-width:768px){
                                font-size: 35px;
                                line-height: 50px;
                            }
                            @media(max-width:576px){
                                font-size: 26px;
                                line-height: 40px;
                            }
                            @media(max-width:450px){
                                font-size: 20px;
                                line-height: 40px;
                            }
                            a{
                                color: #171717;
                                background-image: linear-gradient(to right, #171717 50%, transparent 50%);
                                &:hover{
                                    background-position: 0% 100%;
                                }
                            }
                        }
                        .echo-latest-news-time-views{
                            justify-content: center;
                            position: relative;
                            padding-top: 15px;
                            color: #454545;
                            a{
                                margin: 0;
                                padding: 0 10px;
                                position: relative;
                                &:first-child{
                                    padding-right: 15px;
                                }
                                &::after{
                                    content: '';
                                    position: absolute;
                                    width: 1px;
                                    height: 10px;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    background: #FFFFFF;
                                }
                                &:last-child{
                                    &::after{
                                        display: none;
                                    }
                                }
        
                                i{
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
            .swiper-btn{
                position: absolute;
                width: 70px;
                height: 50px;
                left: 75%;
                transform: translateX(-50%);
                bottom: 35%;
                z-index: 1;
                @media(max-width:1400px){
                    left: 85%;
                }
                @media(max-width:1200px){
                    left: 90%;
                }
                @media(max-width:768px){
                    display: none;
                }
                .swiper-button-next,
                .swiper-button-prev{
                    &::after{
                        display: none;
                    }
                    color: rgba(69, 69, 69, 1);
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    transition: all .4s;
                    font-size: 20px;
                }
                .swiper-button-prev{
                    right: auto;
                    left: 0;
                }
                .swiper-button-next{
                    right: 0;
                    color: #FE5301;
                    left: auto;
                }
            }
            .swiper-pagination{
                left: 75%;
                transform: translateX(-50%);
                bottom: 50px;
                width: max-content;
                color: #DADADA;
                @media(max-width:1400px){
                    left: 85%;
                }
                @media(max-width:1200px){
                    left: 90%;
                }
                @media(max-width:768px){
                    display: none;
                }
                .swiper-pagination-current{
                    color: #FE6821;
                    font-family: 'Plus Jakarta Sans', sans-serif;
                    font-size: 35px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.echo-software-download-area{
    &.home-four{
      .echo-software-download-full-content-bg{
        border-radius: 50px;
        background: url(../images/home-1/our-android-app-download/bg4.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        transition: all .4s;
        &:hover{
            .echo-software-download-img-content{
                transform: scale(110%);
            }
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #181823;
            opacity: 0;
        }
        .echo-software-download-img-content{
            position: absolute;
            top: -1px;
            right: 50px;
            transition: all .4s;
            transform: scale(100%);
        }
        .echo-software-download-full-content{
            .echo-software-download-text-content{
                .echo-software-download-place{
                    .echo-py-hover-white{
                        &:hover{
                            background: #FFF;
                            color: #FF4962 !important;
                            border: 1px solid #FFF !important;
                            svg{
                                path{
                                    fill: #FF4962 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
      }  
    }
    &.sports{
        padding-top: 90px;
        padding-bottom: 90px;
        .echo-software-download-full-content-bg{
            background: url(../images/home-1/our-android-app-download/bg5.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .echo-software-download-full-content{
                .echo-software-download-text-content{
                    .echo-software-download-place{
                        a{
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
} 

.echo-about-area{
    padding: 100px 0;
    .container{
        .echo-about-area-inner{
            .content-area{
                .title{
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 60px;
                    margin-bottom: 30px;
                }
                .sub-title{
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 30px;
                    text-transform: capitalize;
                    color: #5E5E5E;
                }
                .desc{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #5E5E5E;
                }
                .bottom-author-area{
                    display: flex;
                    align-items: center;
                    .content{
                        padding-right: 25px;
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 100%;
                            right: 0;
                            background: #5E5E5E75;
                        }
                        .author-name{
                            margin-bottom: 0;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                    .sign{

                        padding-left: 25px;
                    }
                }
            }
            .hero-image{
                display: flex;
                position: relative;
                .image{
                    width: 380px;
                    img{
                        width: 100%;
                    }
                }
                .image-2{
                    position: absolute;
                    right: -230px;
                }
            }
        }
    }
}
.echo-team-area{
    padding: 90px 0 165px 0;
    .echo-team-area-inner{
        .section-title{
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            text-align: center;
            margin-bottom: 50px;
        }
        .team-wrapper{
            position: relative;
            margin-right: 15px;
            transition: all .4s;
            &:hover{
                .content-area{
                    ul.social-icon{
                        transform: translateX(-50%) scale(1);
                    }
                }
            }
            .image-area{
                img{
                    width: 100%;
                }
            }
            .content-area{
                background: #FFFFFF;
                padding:40px 20px 20px 20px;
                border-radius: 50%;
                position: absolute;
                width: 120%;
                left: 50%;
                transform: translateX(-50%);
                bottom: -90px;
                transition: all .4s;
                
                ul.social-icon{
                    padding: 0;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    width: 65%;
                    left: 50%;
                    transform: translateX(-50%) scale(0);
                    transition: all .4s;
                    li{
                        list-style: none;  
                        text-align: center;
                        margin: 0 5px;
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4){
                            position: absolute;
                            transition: all .4s;
                        }
                        &:nth-child(1){
                            top: -8px;
                            left: 10px;
                        }
                        &:nth-child(2){
                            top: -14px;
                            left: 60px;
                        }
                        &:nth-child(3){
                            top: -14px;
                            right: 60px;
                        }
                        &:nth-child(4){
                            top: -8px;
                            right: 10px;
                        }
                        a{
                            width: 30px;
                            height: 30px;
                            line-height: 31px;
                            display: block;
                            text-align: center;
                            background: #FFFFFF;
                            box-shadow: 5px 5px 10px rgba(0, 0, 1, 0.05);
                            color: #5E5E5E;
                            border-radius: 50%;
                            font-size: 14px;
                            transition: all .4s;
                            &:hover{
                                background: var(--color-primary);
                                color: #FFFFFF;
                            }
                        }
                    }
                }
                .content{
                    text-align: center;
                    .title{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &.team{
        padding: 90px 0 60px 0;
        .echo-team-area-inner{
            .team-wrapper{
                margin-bottom: 100px;
            }
        }
    }
}
.echo-hero-section{
    &.inner-post{
        .echo-hero-baner{
            box-shadow: none;
            margin-bottom: 0;
        }
        .echo-quote-area{
            background: #F9F9F9;
            padding: 20px 20px 20px 25px;
            position: relative;
            margin-bottom: 30px;
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 4px;
                left: 0;
                top: 0;
                background: #F16228;
            }
            .quote-title{
                font-size: 20px;
                line-height: 30px;
                color: #181823;
                text-transform: capitalize;
            }
            .author{
                padding-left: 25px;
                position: relative;
                font-size: 16px;
                line-height: 26px;
                font-weight: 500;
                &::before{
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 1px;
                    background: #5E5E5E;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .echo-financial-area{
            margin-bottom: 40px;
            .image-area{
                margin-bottom: 20px;
            }
            .content{
                .title{
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 60px;
                }
                .desc{
                    color: #5E5E5E;
                }
                .content-wrapper{
                    padding: 0;
                    margin: 0 0 30px 0;
                    display: flex;
                    flex-wrap: wrap;
                    &.first{
                        .wrapper-item{
                            padding-left: 30px;
                        }
                    }
                    .wrapper-item{
                        list-style: none;
                        width: 50%;
                        padding-left: 20px;
                        font-family: 'Bona Nova';
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #000000;
                        position: relative;
                        &::before{
                            content: '\f058';
                            position: absolute;
                            font-family: 'Font Awesome 6 Pro';
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            color: #5E5E5E;
                        }
                    }
                }
                .details-tag,
                .details-share{
                    display: flex;
                    align-items: center;
                    h6{
                        margin-bottom: 0;
                        margin-right: 5px;
                    }
                    button{
                        max-width: max-content;
                        margin: 0 5px;
                        transition: all 0.3s;
                        &:hover{
                            i{
                                color: var(--color-primary);
                            }
                        }
                    }
                }
                .details-tag{
                    justify-content: start;
                    button{
                        padding: 8px 10px;
                        background: #f9f9f9;
                        transition: all 0.3s;
                        &:hover{
                            background:var(--color-primary);
                            color: #ffffff;
                        }
                    }
                }
                .details-share{
                    justify-content: end;
                }
            }   
        }
        .echo-author-area{
            display: flex;
            align-items: center;
            background: #f9f9f9;
            padding: 20px;
            margin-bottom: 40px;
            .image-area{
                flex-basis: 25%;
            }
            .content{
                flex-basis: 75%;
                .title{
                    margin-bottom: 10px;
                }
            }
        }
        .echo-more-news-area{
            padding: 35px 30px;
            background: #f9f9f9;
            margin-bottom: 40px;
            .title{
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
            }
            
            .echo-top-story{
                display: flex;
                margin: 18px 0;
                gap: 15px;
                align-items: center;
                background: #fff;
                padding: 15px;
                .echo-story-picture{
                    flex-basis: 30%;
                    a{
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .echo-story-text{
                    flex-basis: 70%;
                    h6{
                        font-size: 20px;
                        line-height: 30px;
                        margin-bottom: 10px;
                    }
                    a{
                        i{
                            font-size: 16px;
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
        .echo-reply-area{
            background: #f9f9f9;
            padding: 30px;
            margin-bottom: 40px;
            .title{
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
            }
            .comment-inner{
                padding: 0;
                margin: 0;
                .wrapper{
                    list-style: none;
                    display: flex;
                    padding: 40px 0;
                    position: relative;
                    border-bottom: 1px solid #45454530;
                    .image-area{
                        flex-basis: 12%;
                    }
                    .content{
                        flex-basis: 88%;
                        .title{
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 30px;
                            margin-bottom: 0;
                        }
                        a{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                        }
                        .desc{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            color: #454545;
                            margin-top: 10px;
                        }
                    }
                    &:last-child{
                        padding-bottom: 0;
                        border: none;
                    }
                    .reply{
                        position: absolute;
                        top: 35px;
                        right: 0;
                        font-family: 'Bona Nova';
                        font-weight: 700;
                    }
                }
            }
        }
        .echo-comment-box{
            padding: 30px;
            background: #f9f9f9;
            .title{
                font-weight: 700;
                font-size: 25px;
                line-height: 35px;
            }
            form{
                input,
                textarea{
                    border: 1px solid #45454540;
                    margin-bottom: 20px;
                }
                input{
                    height: 60px;

                }
                textarea{
                    padding-top: 10px;
                    min-height: 140px;
                }
                button{
                    width: 170px;
                    height: 60px;
                    background: var(--color-primary);
                    transition: all 0.3s;
                    color: #ffffff;
                    border: 1px solid transparent;
                        &:hover{
                            border: 1px solid #45454530;
                            background: transparent;
                            color: #5E5E5E;
                        }
                }
            }
        }
    }
    &.inner-post-2{
        .echo-hero-baner{
            .content-catagory-tag{
                background: #F05600;
                color: #FFFFFF;
                padding: 10px 20px;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                text-transform: uppercase;
                border-radius: 5px;
            }
            .echo-hero-title{
                margin-bottom: 15px;
            }
        }
        .echo-financial-area{
            .content{
                .content-wrapper{
                    .wrapper-item{
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 30px;
                        &::before{
                            color: #537FE7;
                        }
                    }
                    &.wrapper-2{
                        .wrapper-item{
                            width: 100%;
                            color: #5E5E5E;
                            &::before{
                                display: none;
                            }
                            &::after{
                                content: '';
                                position: absolute;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                background: #5E5E5E;
                            }
                        }
                    }
                }
                .image{
                    &.bottom{
                        margin: 30px 0;
                    }
                }
            }
        }
    }
}
.hero-home-six{
    position: relative;
    .hero-inner{
        position: relative;
        .slider-inner{
            height: 930px;
            position: relative;
            @media(max-width:768px){
                height: 770px;
            }
            @media(max-width:576px){
                height: 600px;
            }
            .swiper-slide{
                overflow: hidden;
            }
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(180deg, rgba(17, 17, 17, 0.65) 35.77%, rgba(0, 0, 0, 0) 100%);
            }
            video{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .content{
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: max-content;
                .hero-hm6-title{
                    max-width: 1100px;
                    h1{
                        color: #FFF;
                        text-align: center;
                        font-family: "DM Sans", sans-serif;
                        font-size: 160px;
                        font-weight: 700;
                        line-height: 160px;
                        margin-bottom: 45px;
                        @media(max-width:1200px){
                            font-size: 130px;
                            line-height: 140px;
                        }
                        @media(max-width:991px){
                            font-size: 100px;
                            line-height: 110px;
                        }
                        @media(max-width:768px){
                            font-size: 74px;
                            line-height: 84px;
                        }
                        @media(max-width:500px){
                            font-size: 50px;
                            line-height: 60px;
                        }
                    }
                }
            }
        }
        .swiper-pagination{
            position: absolute;
            width: max-content;
            left: 70px;
            bottom: 100px;
            font-size: 25px;
            color: #FFFFFF;
            @media(max-width:1200px){
                left: 50px;
            }
            @media(max-width:900px){
                left: 25px;
            }
            @media(max-width:768px){
                display: none;
            }
            .swiper-pagination-current{
                font-size: 50px;
            }
        }
        .swiper-btn-area{
            position: absolute;
            bottom: 65px;
            width: max-content;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            display: block;
            @media(max-width:768px){
                display: none;
            }
            .swiper-btn{
                &::after{
                    display: none;
                }
                display: block;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: unset;
                border-radius: 50%;
                border: 1px solid #FFFFFF;
                &.swiper-button-prev{
                    margin-bottom: 35px;
                }
                &.swiper-button-next{
                    background: #FFCC48;
                    border: 1px solid #FFCC48;
                }

            }
        }
        .pagination-area{
            position: absolute;
            bottom: 0;
            right: 0;
            .banner-blog-thumbs{
                .banner-single-thumb{
                    cursor: pointer;
                    width: 200px;
                    @media(max-width:1440px){
                        width: 180px;
                    }
                    @media(max-width:1200px){
                        width: 120px;
                    }
                    @media(max-width:500px){
                        width: auto;
                    }
                }
            }
        }
        .border{
            width: 35%;
            height: 1px;
            background: #6D6D6D;
            position: absolute;
            border: none !important;
            bottom: 115px;
            z-index: 1;
            left: 12%;
            @media(max-width:1550px){
                width: 30%;
                left: 15%;
            }
            @media(max-width:1200px){
                width: 25%;
                left: 20%;
            }
            @media(max-width:768px){
                display: none;
            }
        }
    }
}

.hero-hm6-btn{
    .hm6-btn-1{
        background: #FFCC48;
        width: 220px;
        height: 80px;
        display: block;
        line-height: 85px;
        border-radius: 70px;
        margin: auto;
        color: #2B2004;
        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 18px;
        font-weight: 700;
        @media(max-width:991px){
            width: 200px;
            height: 70px;
            line-height: 73px;
        }
        @media(max-width:991px){
            width: 180px;
            height: 60px;
            line-height: 63px;
            font-size: 16px;
        }
        img{
            margin-top: 4px;
            margin-left: 5px;
            &.arrow-3,
            &.arrow-4{
                margin-top: 0;
            }
        }
    }
}

.hm8-btn-1{
    background: #D00032;
    width: 210px;
    height: 70px;
    display: block;
    line-height: 75px;
    border-radius: 50px;
    font-family: var(--home-5-title-font);
    color: #fff;
    margin-top: 50px;
    text-align: center;
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: 500;
    
    @media(max-width:991px){
        width: 200px;
        height: 70px;
        line-height: 73px;
    }
    @media(max-width:991px){
        width: 180px;
        height: 60px;
        line-height: 63px;
        font-size: 16px;
    }
    img{
        margin-top: 4px;
        margin-left: 5px;
        &.arrow-3,
        &.arrow-4{
            margin-top: 0;
        }
    }
}

.innerpage{
    #sidebar{
        width: 370px;
        max-width: max-content;
        height: max-content;
        position: absolute;
    }
}
#sidebar.fixed {
    position: fixed;
    top: 0;
    width: 370px;
  }

  .hm6-btn-1{
    position: relative;
    padding-right: 20px;
    &:hover{
        transition: all .4s;
        background: #ffffff;
        .arrow-1{
            opacity: 0;
            transform: translate(10px, -50%);
        }
        .arrow-2{
            opacity: 1;
            transform: translate(10px, -50%);
        }
    }
    img{
        transition: all .4s;
        &.arrow-1{
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            right: 40px;
            @media(max-width:991px){
                right: 33px;
            }
        }
        &.arrow-2{
            position: absolute;
            right: 50px;
            top: 50%;
            opacity: 0;
            transform: translate(0, -50%);
            @media(max-width:991px){
                right: 43px;
            }

        }
    }
    &.small{
        padding-right: 35px;
    } 
}

.echo-banner-area{
    &.home-seven{
        padding: 70px 0;
        .right-side-banner{
            @media(max-width:991px){
                display: flex;
                flex-wrap: wrap;
            }
            .echo-hero-baner.small{
                text-align: left;
                display: flex; 
                align-items: center;
                gap: 20px;
                margin-bottom: 33px;
                @media(max-width:1200px) and (min-width:992px){
                    gap: 15px;
                }
                @media(max-width:991px){
                    width: 50%;
                }
                @media(max-width:600px){
                    width: 100%;
                }
                .echo-hero-banner-main-img{
                    flex-basis: 30%;
                    @media(max-width:600px){
                        flex-basis: auto;
                    }
                }
                .content{
                        margin-top: 0;
                        flex-basis: 70%;
                        .author{
                            text-align: left;
                            font-weight: 500;
                        }
                        .echo-hero-title{
                            text-align: left;
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: 500;
                            @media(max-width:1200px) and (min-width:992px){
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }
                }
            }
        }
    }
    &.home-nine{
        padding: 60px 0;
        .slider-area{
            margin-right: 20px;
            position: relative;
            @media(max-width:1500px){
                margin-right: 0;
            }
            @media(max-width:1200px){
                margin-bottom: 30px;
            }
            .swiper-btn-area{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 95%;
                z-index: 1;
                @media(max-width:576px){
                    display: none;
                }
                .swiper-btn{
                    &::after{
                        display: none;
                    }
                    width: 55px;
                    height: 55px;
                    line-height: 55px;
                    border-radius: 50%;
                    text-align: center;
                    border: 1px solid #FFFFFF;
                    background: none;
                    transition: all .3s;
                    svg{
                        path{
                            fill: #FFFFFF;
                            transition: all .3s;
                        }
                    }
                    &:hover{
                        background: #F5D253;
                        border: 1px solid #F5D253;
                        svg{
                            path{
                                fill: #171717;
                            }
                        }
                    }

                }
            }
        }
        .swiper{
            .echo-hero-baner{
                background: url(../images/home-1/hero/hero-benar13.png);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                height: 820px;
                position: relative;
                z-index: 1;
                @media(max-width:576px){
                    height: 600px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 13, 4, 0.50);
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
                .content{
                    text-align: center;
                    position: absolute;
                    width: 70%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @media(max-width:991px){
                        width: 90%;
                    }
                    .author{
                        color: #F5D253;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                        margin-bottom: 20px;
                    }
                    .echo-hero-title{
                        font-size: 60px;
                        font-weight: 700;
                        line-height: 70px;
                        @media(max-width:991px){
                            font-size: 50px;
                            line-height: 60px;
                        }
                        @media(max-width:768px){
                            font-size: 40px;
                            line-height: 50px;
                        }
                        @media(max-width:450px){
                            font-size: 24px;
                            line-height: 40px;
                        }
                        a{
                            color: #FFFFFF;
                            background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                        }
                    }
                    .echo-hero-discription{
                        color: #E2E2E2;
                        text-align: center;
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                        width: 70%;
                        margin: 0 auto 40px auto;
                        @media(max-width:991px){
                            width: 100%;
                        }
                    }
                    .read-more-btn{
                        width: 160px;
                        height: 60px;
                        line-height: 60px;
                        display: block;
                        border: 1px solid #F5D253;
                        color: #F5D253;
                        margin: auto;
                    }
                }
                &.two{
                    background: url(../images/home-1/hero/hero-benar14.png);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &.three{
                    background: url(../images/home-1/hero/hero-benar15.png);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .echo-hero-baner.small{
            margin-bottom: 40px;
            .echo-hero-banner-main-img{
                @media(max-width:1200px){
                    img{
                        width: 100%;
                    }
                }
            }
            .content{
                margin-top: 20px;
                .author{
                    color: #FF9A82;
                    text-align: center;
                    font-family: 'DM Sans', sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 10px;
                }
                .echo-hero-title{
                    text-align: center;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 50px;
                    @media(max-width:1500px) and (min-width:1200px){
                        font-size: 30px;
                        line-height: 40px;
                    }
                    @media(max-width:450px){
                        font-size: 24px;
                        line-height: 40px;
                    }
                    a{
                        color: #181823;
                        background-image: linear-gradient(to right, #000 50%, transparent 50%);
                        &:hover{
                            color: #003811;
                            background-image: linear-gradient(to right, #003811 50%, transparent 50%);
                        }
                    }
                }
            }
        }
    }
    &.home-ten{
        .banner-inner{
            display: flex;
            @media(max-width:1200px){
                flex-wrap: wrap;
            }
            .left-side-image{
                width: 55%;
                @media(max-width:1200px){
                    width: 100%;
                }
                .image-area{
                    img{
                        width: 100%;
                    }
                }
            }
            .right-side-content{
                width: 45%;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media(max-width:1200px){
                    width: 100%;
                }
                .image-area{
                    display: flex;
                    width: 100%;
                    img{
                        width: 50%;
                    }
                }
                .content{
                    width: 100%;
                    height: 100%;
                    background: #1A1A1A;
                    padding: 100px 80px;
                    @media(max-width:1600px){
                        padding: 80px;
                    }
                    @media(max-width:1366px) and (min-width:1200px){
                        padding: 70px;
                    }
                    @media(max-width:1200px){
                        padding: 80px 40px;
                    }
                    @media(max-width:576px){
                        padding: 80px 20px;
                    }
                    .echo-hero-title{
                        color: #FFF;
                        font-family: var(--home-5-title-font);
                        font-size: 80px;
                        font-weight: 500;
                        line-height: 1;
                        text-transform: uppercase;
                        @media(max-width:1600px){
                            font-size: 65px;
                        }
                        @media(max-width:1366px) and (min-width:1200px){
                            font-size: 54px;
                        }
                        @media(max-width:576px){
                            font-size: 48px;
                            line-height: 1.12;
                        }
                        @media(max-width:450px){
                            font-size: 36px;
                        }
                        a{
                            color: #FFF;
                            background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                            background-position: 100% 85%;
                            &:hover{
                                background-position: 0 85%;
                            }
                        }
                    }
                }
            }
        }
    }
}
.home-seven{
    .echo-hero-baner{
        text-align: center;
        padding: 45px 30px;
        border: 1px solid rgba(69, 69, 69, 0.10);
        margin-bottom: 30px;
        @media(max-width:991px){
            margin-top: 30px;
        }
        @media(max-width:500px){
            padding: 45px 20px;
        }
        .content{
            margin-top: 25px;
            .author{
                color: #924FFF;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                text-transform: uppercase;
                margin-bottom: 20px;
                text-decoration: underline;
                text-underline-offset: 7px;
            }
            .echo-hero-title{
                text-align: center;
                font-family: 'Jost', sans-serif;
                font-size: 40px;
                font-weight: 600;
                line-height: 50px;
                margin-bottom: 10px;
                text-transform: none;
                @media(max-width:500px){
                    font-size: 24px;
                    line-height: 34px;
                }
            }
        }
        &.last{
            margin-bottom: 0;
        }
        &.small{
            padding: 25px 20px;
            @media(max-width:991px){
                margin-top: 0;
            }
            .content{
                .author{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    margin-bottom: 15px;
                }
                .echo-hero-title{
                    font-size: 25px;
                    font-weight: 600;
                    line-height: 35px;
                    margin-bottom: 0;
                    @media(max-width:1200px) and (min-width:992px){
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}
.home-eight{
    .echo-banner-area{
        background: rgba(253, 242, 255, 1);
        .banner-inner{
            .right-side-banner{
                background: #47164F;
                padding: 0 30px;
                @media(max-width:450px){
                    padding: 0 15px;
                }
                .echo-hero-baner{
                    border-bottom: 1px solid rgba(89, 30, 99, 1);
                    margin-bottom: 0;
                }
            }
            .left-side{
                .echo-hero-baner{
                    .content{
                        .echo-hero-title{
                            a{
                                color: #181823;
                                background-image: linear-gradient(to right, #000 50%, transparent 50%);
                            }
                        }
                    }
                }
            }

        }
    }
    .echo-hero-baner{
        text-align: center;
        padding: 0;
        border: none;
        margin-bottom: 30px;
        .content{
            margin: 0;
            padding: 40px;
            padding-top: 60px;
            position: relative;
            background: #FFFFFF;
            box-shadow: 30px 30px 60px 0px rgba(69, 0, 158, 0.05);
            @media(max-width:576px){
                padding: 60px 20px 20px;
            }
            @media(max-width:450px){
                padding: 60px 15px 20px;
            }
            .author{
                color: #FFFFFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;
                background: #FE5301;
                width: 150px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: absolute;
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                @media(max-width:576px){
                    font-size: 14px;
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    top: -20px;
                }
            }
            .echo-hero-title{
                text-align: center;
                font-family: 'Dm Sans', sans-serif;
                font-size: 40px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 25px;
                text-transform: none;
                @media(max-width:576px){
                    font-size: 30px;
                    line-height: 40px;
                }
                @media(max-width:450px){
                    font-size: 22px;
                    line-height: 35px;
                }
            }
            .echo-hero-area-titlepost-post-like-comment-share{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                @media (max-width:767px){
                    flex-wrap:wrap ;
                }

                .echo-hero-area-like-read-comment-share{
                    a{
                        font-size: 14px;
                        font-weight: 500;
                        color: #5E5E5E;
                       i{
                            margin: 0 4px;
                       }
                    }
                }
            }
        }
        &.last{
            margin-bottom: 0;
        }
    }
    .echo-banner-area.home-seven .right-side-banner{

        .echo-hero-baner.small{
            padding: 30px 0;
            @media(max-width:1200px) and (min-width:991px){
                padding: 12px 0;
            }
            @media(max-width:450px){
                padding: 15px 0;
            }
            .echo-hero-banner-main-img{
                flex-basis: 38%;
                @media(max-width:450px){
                    flex-basis: 30%;
                }
            }
            .content{
                padding-top: 0;
                background: none;
                padding: 0;
                flex-basis: 62% !important;
                @media(max-width:450px){
                    flex-basis: 62% !important;
                }
                .author{
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 22px;
                    margin-bottom: 7px;
                    margin-top: 0;
                    color: #FF7474;
                    position: unset;
                    width: auto;
                    height: auto;
                    background: none;
                    transform: translateX(0);
                }
                .echo-hero-title{
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 35px;
                    margin-bottom: 0;
                    color: #FFFFFF;
                    a{
                        color: #FFFFFF;
                        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                    }
                    @media(max-width:991px){
                        font-size: 22px;
                        line-height: 1.2;
                    }
                    @media(max-width:450px){
                        font-size: 16px;
                    }
                }
                .echo-story-text{
                    margin-top: 5px;
                    font-size: 16px;
                    font-family: 'Inter', sans-serif;
                    color: #FFF;
                    font-weight: 300;
                    line-height: 26px;
                    display: flex;
                    align-items: center;
                    @media(max-width:450px){
                        font-size: 14px;
                        line-height: 1.1;
                    }
                    svg{
                        width: 15px;
                        height: 15px;
                        margin-right: 5px;
                        path{
                            fill: #FFF;
                        }
                    }
                }
            }
        } 
    }
    .echo-hero-section.home-eight{
        background: #FDF2FF;
        .hm5-feature-title{
            margin-bottom: 60px;
            .hm-5-main-title{
                display: flex;
                align-items: center;
                position: relative;
                img{
                    margin-right: 10px;
                }
                h2{
                    color: #171717;
                    font-family: 'Inter', sans-serif;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-bottom: 0;
                    text-transform: none;
                    &::after{
                        display: none;
                    }
                }
            }
        }
        .echo-full-hero-content{
            .right-side-banner{
                .echo-hero-baner.small{
                    padding: 35px 0;
                    display: flex;
                    align-items: center;
                    background: none;
                    box-shadow: none;
                    gap: 20px;
                    margin-bottom: 0;
                    border-bottom: 1px solid #AD6EB83d;
                    &.last{
                        border: none;
                    }
                    .echo-hero-banner-main-img{
                        flex-basis: 30%;
                    }
                    .content{
                        padding-top: 0;
                        background: none;
                        box-shadow: none;
                        padding: 0;
                        flex-basis: 70% !important;
                        .author{
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 22px;
                            margin-bottom: 7px;
                            margin-top: 0;
                            color: #FF7474;
                            position: unset;
                            width: auto;
                            height: auto;
                            background: none;
                            transform: translateX(0);
                            text-align: start;
                            margin-bottom: 0;
                        }
                        .echo-hero-title{
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            margin-bottom: 0;
                            color: #171717;
                            text-align: start;
                            font-weight: 700;
                            margin-top: 0;
                            a{
                                color: #171717;
                                background-image: linear-gradient(to right, #171717 50%, transparent 50%);
                            }
                        }
                        .echo-story-text{
                            margin-top: 5px;
                            font-size: 14px;
                            font-family: 'Inter', sans-serif;
                            color: #171717;
                            font-weight: 400;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;
                            svg{
                                width: 15px;
                                height: 15px;
                                margin-right: 5px;
                                path{
                                    fill: #171717;
                                }
                            }
                        }
                    }
                } 
            }
            .echo-hero-baner{
                box-shadow: none;
                .content{
                    padding-top: 30px;
                    padding-bottom: 0;
                    background: none;
                    box-shadow: none;
                    .author{
                        top: -40px;
                        font-size: 14px;
                        width: 120px;
                        height: 40px;
                        line-height: 40px;

                    }
                    .echo-hero-title{
                        font-size: 30px;
                        line-height: 1.2;
                        @media(max-width:991px) and (min-width:768px){
                            font-size: 26px;
                        }
                        @media(max-width:450px){
                            font-size: 22px;
                            line-height: 35px;
                        }
                    }
                    .echo-hero-area-titlepost-post-like-comment-share{
                        width: max-content;
                        margin: auto;
                        padding-top: 25px;
                        border-top: 1px solid #AD6EB83d;
                    }
                }
            }
            .top-area{
                margin-bottom: 100px;
            }
            .bottom-area{
                .echo-hero-baner{
                    margin-bottom: 30px;
                    .content{
                        padding: 15px;
                        .echo-hero-title{
                            font-size: 25px;
                            font-weight: 500;
                            @media(max-width:450px){
                                font-size: 22px;
                                line-height: 35px;
                            }
                        }
                    }
                }
            }
            .echo-right-ct-1{
                .hm5-feature-title{
                    &.right{
                        margin-bottom: 65px;
                    }
                }
                .echo-feature-area-right-site-follower{
                    background: none;
                    padding: 0;
                    ul{
                        justify-content: space-between;
                        gap: 25px;
                        li{
                            width: 46%;
                            border: none;
                            background: #FFFFFF;
                            text-align: center;
                            padding-top: 0;
                            padding: 18px 0;
                            @media(max-width:450px){
                                width: 100%;
                            }
                            a{
                                margin-bottom: 0;
                                color: #5E5E5E;
                            }
                        }
                    }
                } 
                .echo-home-1-hero-area-top-story{
                background: none;
                padding: 30px 0;
                h5{
                    font-size: 30px;
                    line-height: 1.2;
                    font-family: 'Inter', sans-serif;
                    font-weight: 700;
                }
                .right-side-banner{
                    .echo-hero-baner{
                        border: none;
                        padding: 15px 0;
                    }
                }
                }
                .echo-feature-area-right-site-gallery{
                    h5{
                        font-size: 30px;
                        line-height: 1.2;
                        font-family: 'Inter', sans-serif;
                        font-weight: 700;
                    }
                    .gallery-wrapper{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-wrap: wrap;
                        @media(max-width:768px){
                            justify-content: space-between;

                        }
                        .wrapper{
                            list-style: none;
                            width: 50%;
                            @media(max-width:768px){
                                width: 48%;
                                img{
                                    width: 100%;
                                }
                            }
                            @media(max-width:450px){
                                width: 100%;
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .echo-banner-area.home-seven .right-side-banner .echo-hero-baner.small{
        @media(max-width:767px){
            width: 100%;
        }
    }
    .echo-footer-area .echo-row .echo-footer-content-4 .echo-subscribe-box-button form .echo-subscribe-input-fill input{
        background: none;
    }
}
.home-nine{
    .echo-hero-baner{
        text-align: center;
        .content{
            .author{
                color: #F5D253;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 20px;
                font-family: 'DM Sans', sans-serif;
            }
            .echo-hero-title{
                font-size: 60px;
                font-weight: 700;
                line-height: 70px;
                a{
                    color: #181823;
                    background-image: linear-gradient(to right, #000 50%, transparent 50%);
                    &:hover{
                        color: #003811;
                        background-image: linear-gradient(to right, #003811 50%, transparent 50%);
                    }
                }
            }
            .echo-hero-discription{
                color: #E2E2E2;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                width: 70%;
                margin: 0 auto 40px auto;
            }
            .read-more-btn{
                width: 160px;
                height: 60px;
                line-height: 60px;
                display: block;
                border: 1px solid #F5D253;
                color: #F5D253;
                margin: auto;
                @media(max-width:576px){
                    width: 120px;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }
}
html[data-theme=dark] body{
    &.home-nine{
        .echo-hero-baner{
            .content{
                .echo-hero-title{
                    a{
                        color: #FFFFFF;
                        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                    }
                }
            }
        }
    }
}

.echo-dishes-area{
    .section-inner{
        padding-bottom: 80px;
        border-bottom: 1px solid #003811;
        display: flex;
        @media(max-width:768px){
            flex-wrap: wrap;
        }
        .content-area{
            width: 50%;
            flex: 1;
            background: #003811;
            display: flex;
            align-items: center;
            @media(max-width:768px){
                flex: unset;
                width: 100%;
                order: 2;
                padding: 40px 0;
            }
            .echo-hero-baner{
                padding: 0 80px;
                text-align: start;
                @media(max-width:991px){
                    padding: 0 30px;
                }
                @media(max-width:450px){
                    padding: 0 15px;
                }
                .echo-hero-title{
                   font-size: 80px;
                   line-height: 1.12; 
                   margin-bottom: 35px;
                   @media(max-width:1600px){
                    font-size: 65px;
                   }
                   @media(max-width:1366px){
                    font-size: 55px;
                   }
                   @media(max-width:1200px){
                    font-size: 42px;
                   }
                   @media(max-width:991px){
                    font-size: 36px;
                   }
                   @media(max-width:450px){
                    font-size: 30px;
                   }
                   a{
                    color: #FFFFFF;
                    background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                   }
                }
                .read-more-btn{
                    margin: 0;
                    text-align: center;
                    border: 1px solid #FFFFFF;
                    color: #FFFFFF;
                }
            }
        }
        .image-area{
            width: 50%;
            flex: 1;
            @media(max-width:768px){
                flex: unset;
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
    }
}
.echo-new-dishes-area{
    padding-top: 80px;
    .hm5-feature-title{
        .hm-5-main-title{
            h2{
                font-size: 60px;
                line-height: 70px;
                margin-bottom: 60px;
                @media(max-width:991px){
                    font-size: 50px;
                    line-height: 60px;
                }
                @media(max-width:576px){
                    font-size: 26px;
                    line-height: 40px;
                }
            }
        }
    }
    .section-inner{
        .echo-hero-baner{
            margin: 0 12px;
            margin-bottom: 40px;
            @media(max-width:768px){
                margin: 0 0 40px 0;
            }
            .content{
                margin-top: 25px;
                .author{
                    color: #FF9A82;
                    margin-bottom: 10px;
                }
                .echo-hero-title{
                    font-size: 40px;
                    line-height: 1.12;
                    @media(max-width:1600px){
                        font-size: 36px;
                    }
                    @media(max-width:1400px){
                        font-size: 30px;
                    }
                    @media(max-width:1200px){
                        font-size: 20px;
                    }
                }
            }
        }
        .button-area{
            position: relative;
            margin-top: 60px;
            &::after,
            &::before{
                content: '';
                position: absolute;
                width: 37%;
                height: 1px;
                top: 50%;
                transform: translateY(-50%);
                background: #003811;
                @media(max-width:768px){
                    display: none;
                }
            }
            &::after{
                right: 0;
            }
            &::before{
                left: 0;
            }
            .learn-more-btn{
                width: 170px;
                height: 60px;
                border: 1px solid #FE5301;
                margin: 0 auto;
                display: block;
                text-align: center;
                line-height: 60px;
                color: #FE5301;
                font-size: 18px;
            }
        }
    }
}

.echo-food-review-area{
    padding-top: 80px;
    .hm5-feature-title{
        .hm-5-main-title{
            h2{
                font-size: 60px;
                line-height: 70px;
                margin-bottom: 60px;
                @media(max-width:991px){
                    font-size: 50px;
                    line-height: 60px;
                }
                @media(max-width:576px){
                    font-size: 28px;
                    line-height: 40px;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .section-inner{
        padding-bottom: 50px;
        border-bottom: 1px solid #003811;
    }
    .left-side-content{
        margin-right: 10px;
        .echo-hero-baner{
            text-align: left;
            .content{
                margin-top: 20px;
                .author{
                    color: #FF9A82;
                    margin-bottom: 10px;
                }
                .echo-hero-title{
                    font-size: 80px;
                    line-height: 1.12;
                    @media(max-width:1400px) and (min-width:991px){
                        font-size: 48px;
                    }
                    @media(max-width:991px){
                        font-size: 54px;
                    }
                    @media(max-width:768px){
                        font-size: 40px;
                    }
                    @media(max-width:450px){
                        font-size: 28px;
                    }
                }
            }
        }
    }
    .right-side-banner{
        .echo-hero-baner.small{
            display: flex;
            align-items: center;
            gap: 30px;
            margin-bottom: 35px;
            @media(max-width:576px){
                gap: 20px;
            }
            .echo-hero-banner-main-img{
                flex-basis: 40%;
            }
            .content{
                flex-basis: 60%;
                text-align: start;
                .author{
                    color: #FF9A82;
                    margin-bottom: 10px;
                    font-size: 16px;
                }
            }
            .echo-hero-title{
                font-size: 35px;
                line-height: 45px;
                margin-bottom: 0;
                @media(max-width:1400px) and (min-width:991px){
                    font-size: 30px;
                    line-height: 40px;
                }
                @media(max-width:576px){
                    font-size: 26px;
                    line-height: 36px;
                }
                @media(max-width:450px){
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }

}

.echo-trending-news-area{
    &.home-seven{
        padding: 100px 0;
        .section-inner{
            .left-side-inner,
            .right-side-inner{
                .hm5-feature-title{
                    .hm-5-main-title{
                        h2{
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 50px;
                            margin-bottom: 60px;
                            font-family: 'Jost', sans-serif;
                        }
                    }
                }
            }
            .left-side-inner{
                .echo-hero-baner{
                    .echo-hero-banner-main-img{
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .right-side-inner{
            .post-area{
                border: 1px solid rgba(69, 69, 69, 0.10);
                padding: 28px 25px;
                .echo-hero-baner{
                    border: none;
                    padding: 0;
                }
                .echo-hero-baner.small{
                    text-align: left;
                    display: flex; 
                    align-items: center;
                    gap: 20px;
                    margin-bottom: 33px;
                    padding-bottom: 34px;
                    border-bottom: 1px solid rgba(69, 69, 69, 0.10);
                    .echo-hero-banner-main-img{
                        flex-basis: 30%;
                    }
                    .content{
                        margin-top: 0;
                        flex-basis: 70%;
                        .author{
                            text-align: left;
                            color: #909090;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 22px;
                            text-transform: uppercase;
                            text-decoration: none;
                            margin-bottom: 8px;
                            svg{
                                width: 12px;
                                height: 12px;
                                margin-right: 5px;
                            }
                        }
                        .echo-hero-title{
                            text-align: left;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 26px;
                        }
                    }
                    &.last{
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border: none;
                    }
                }
            }
        }
    }
    &.home-eight{
        padding: 100px 0;
        @media(max-width:576px){
            padding: 50px 0;
        }
        .hm5-feature-title{
            margin-bottom: 60px;
            .hm-5-main-title{
                display: flex;
                align-items: center;
                position: relative;
                img{
                    margin-right: 10px;
                }
                h2{
                    color: #171717;
                    font-family: 'Inter', sans-serif;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-bottom: 0;
                    @media(max-width:576px){
                        font-size: 26px;
                    }
                    &::after{
                        display: none;
                    }
                }
            }
            .hero-hm7-btn{
                @media(max-width:576px){
                    display: none;
                }
            }
        }
        .section-inner{
            .echo-hero-baner{
                margin-bottom: 0;
                @media(max-width:1200px){
                    margin-bottom: 30px;
                }
                .content{
                    background: none;
                    box-shadow: none;
                    padding: 10px;
                    padding-top: 40px;
                    .author{
                        width: 120px;
                        height: 40px;
                        font-size: 14px;
                        line-height: 40px;
                        top: -20px;
                    }
                    .echo-hero-title{
                        font-size: 25px;
                        line-height: 35px;
                        @media(max-width:450px){
                            font-size: 22px;
                            line-height: 35px;
                        }
                    }
                    .echo-hero-area-titlepost-post-like-comment-share{
                        width: max-content;
                        margin: auto;
                        padding-top: 20px;
                        border-top: 1px solid #17171747;
                    }
                }
            }
        }
    }
}
.echo-photography-area{
    padding-top: 90px;
    .hm5-feature-title{
        margin-bottom: 50px;
        @media(max-width:576px){
            margin-bottom: 30px;
        }
        .hm-5-main-title{
            h2{
                color: #1A1A1A;
                font-size: 80px;
                font-style: normal;
                font-weight: 500;
                line-height: 85px;
                text-transform: uppercase;
                font-family: var(--home-5-title-font);
                margin-bottom: 0;
                @media(max-width:768px){
                    font-size: 64px;
                    line-height: 70px;
                }
                @media(max-width:576px){
                    font-size: 40px;
                    line-height: 50px;
                }
                &::after{
                    display: none;
                }
            }
        }
        .hm5-area-title-btn{
            @media(max-width:768px){
                display: none;
            }
        }
        .hm8-btn-1{
            margin-top: 0;
        }
    }
    .section-inner{
        flex-wrap: wrap;
        .echo-hero-baner{
            width: 50%; 
            position: relative;
            z-index: 1;
            transition: all 500ms;
            @media(max-width:767px){
                width: 100%;
            }
            &:hover{
                .echo-hero-banner-main-img{
                    a{
                        &::after{
                            opacity: 0.5;
                            background: #D00032;
                            mix-blend-mode: color;
                        }
                        img{
                            transform: scale(110%);
                        }
                    }
                }
            }
            .echo-hero-banner-main-img{
                overflow: hidden;
                a{
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
                        mix-blend-mode: multiply;
                        top: 0;
                        left: 0;
                        transition: all 500ms;
                    }
                }
            }
            .content{
                position: absolute;
                left: 50px;
                bottom: 50px;
                z-index: 1;
                width: 75%;
                @media(max-width:991px){
                    width: 85%;
                    left: 20px;
                }
                @media(max-width:450px){
                    bottom: 25px;
                }
                .echo-hero-title{
                    font-family: var(--home-5-title-font);
                    font-size: 60px;
                    font-weight: 500;
                    line-height: 65px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    @media(max-width:1366px){
                        font-size: 42px;
                        line-height: 1.2;
                    }
                    @media(max-width:1200px){
                        font-size: 34px;
                    }
                    @media(max-width: 991px) and (min-width:768px){
                        font-size: 30px;
                    }
                    @media(max-width:767px){
                        font-size: 46px;
                    }
                    @media(max-width:576px){
                        font-size: 36px;
                    }
                    @media(max-width:450px){
                        font-size: 24px;
                    }
                    a{
                        color: #FFF;
                        background-position: 100% 85%;
                        background-image: linear-gradient(to right, #fff 50%, transparent 50%);
                        &:hover{
                            background-position: 0 85%;
                        }
                    }
                }
            }
        }
    }
    &.area2{
        .section-inner{
            .top{
                .echo-hero-baner{
                    width: 100%;
                    .content{
                        width: 50%;
                    }
                }
            }
            .bottom{
                @media(max-width:1200px){
                    flex-wrap: wrap;
                }
                .echo-hero-baner{
                    .content{
                        .echo-hero-title{
                            font-size: 40px;
                            line-height: 45px;
                            @media(max-width:1366px) and (min-width:1200px){
                                font-size: 32px;
                                line-height: 40px;
                            }
                        }
                    }
                }
            }
            .echo-hero-baner{
                &:hover{
                    a{
                        &::after{
                            opacity: 1;
                            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
                            mix-blend-mode: multiply;
                        }
                    }
                }
            }
        }
    }
    &.area3{
        .section-inner{
            .bottom{
                @media(max-width:1200px){
                    flex-wrap: wrap;
                }
                .echo-hero-baner{
                    .content{
                        .echo-hero-title{
                            font-size: 40px;
                            line-height: 45px;
                        }
                    }
                }
            }
            .echo-hero-baner{
                a{
                    &::after{
                        display: none;
                    }
                }
                
            }
        }
    }
}